<template>
    <el-table
        v-if="data.length > 0"
        v-loading="$wait.is('loading')"
        :data="data"
        element-loading-spinner="custom-spinner"
    >
        <el-table-column :label="$t('managers.name')" prop="name" sortable :show-overflow-tooltip="true">
            <template slot-scope="scope">
                <router-link :to="`/managers/${scope.row.uuid}/general`" class="hover:text-red-500 hover:font-semibold">
                    {{ scope.row.name }} {{ scope.row.surname }}
                </router-link>
            </template>
        </el-table-column>
        <el-table-column :label="$t('managers.email')" prop="email" sortable />
        <el-table-column width="100">
            <template slot-scope="scope">
                <el-button-group>
                    <el-tooltip :enterable="false" :openDelay="500" effect="dark" :content="$t('common.details')" placement="left">
                        <router-link
                            :to="`/managers/${scope.row.uuid}/dashboard`"
                            class="el-button el-tooltip el-button--primary el-button--mini"
                        >
                            <fa-icon :icon="['fas', 'info']" fixed-width />
                        </router-link>
                    </el-tooltip>
                    <el-tooltip :enterable="false" :openDelay="500" effect="dark" :content="$t('common.bring_back')" placement="top">
                        <el-button type="warning" size="mini" :disabled="!userCan('update managers')" @click="handleRestore(scope.row.uuid)">
                            <fa-icon :icon="['fas', 'redo']" fixed-width />
                        </el-button>
                    </el-tooltip>
                    <el-tooltip :enterable="false" :openDelay="500" effect="dark" :content="$t('common.delete')" placement="top">
                        <el-button type="danger" size="mini" :disabled="!userCan('delete managers')" @click="handleDelete(scope.row.uuid)">
                            <fa-icon :icon="['fas', 'trash']" fixed-width />
                        </el-button>
                    </el-tooltip>
                </el-button-group>
            </template>
        </el-table-column>
    </el-table>
</template>

<script>
export default {
    props: {
        data: {
            type: Array,
            default() {
                return [];
            },
        },
    },

    methods: {
        handleRestore(value) {
            this.$emit('handleRestore', value);
        },

        handleDelete(value) {
            this.$emit('handleDelete', value);
        },
    },
};
</script>
